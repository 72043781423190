import React, { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { userActions } from "../../Actions";

import { ConfirmationPopup } from './ConfirmationPopup';

const DashboardNav = ({ active, session, LoginStudent, Logout }) => {

    const [settings, setSettings] = useState('');
    const [classes, setClasses] = useState('');
    const [game, setGame] = useState('');
    const token = localStorage.getItem("token");
    const componentRef = useRef(null);

    const [id, setId] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [showBanner, setBanner] = useState('hidden');

    useEffect(()=> {
        getUserInfo()
        switch (active) {
            case 'settings':
                setSettings('active');
                break;
            case 'game':
                setGame('active');
                break;
            case 'classes':
                setClasses('active');
                break;
            default:    
        }
    })

    const getUserInfo = () => {
        userActions.GetUserData(token, (data) => {
            if (data) {
                if(data.student_id == null) {
                    setId(data.id);
                    setFirstName(data.first_name);
                    setLastName(data.last_name);
                }
                else {
                    setTimeout(() => {
                        Logout();
                    }, 750);
                }
            }
        })
    }

    const TryGetStudentLinkedToTeacher = () => {
        userActions.GetStudentLinkedToTeacher(id, (data) => {
            if(data.result) {
                let username = data.username;
                let student_id = data.student_id;
                let classroom_code = data.classroom_code;
                let useCaptcha = false;
                LoginStudent({ username, student_id, classroom_code, useCaptcha });
            }
            else {
                setBanner('');
                //
            }
        })
    }

    const CreateStudentTeacherAccount = (confirmation, code) => {
        if (confirmation) {
            userActions.RegisterStudentAsTeacher(id, firstName, lastName, code);
        }
        setBanner('hidden');
    }

    return (
        <Fragment>
            <nav className={'dashboard-nav'} ref={componentRef}>
                <div className={'header'}>IDA GEM</div>
                <div className={'nav'}>
                    <a href='/Dashboard' className={classes}>Dashboard</a>
                    <a className={game} onClick={() => {TryGetStudentLinkedToTeacher()}}>Game</a>
                    <a href={'/settings'} className={settings}>Settings</a>
                </div>
            </nav>
            <ConfirmationPopup display={showBanner} type={"studentTeacher"} data={{}} confirmation={CreateStudentTeacherAccount} />
        </Fragment>
    )
};


function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    LoginStudent: userActions.LoginStudent,
    Logout: userActions.Logout,
};

const connection = connect(mapState, actionCreators)(DashboardNav);
export { connection as DashboardNav };