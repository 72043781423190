//--------------------------------------------------
//Phase Component
//--------------------------------------------------

import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';
import { useIdleTimeout } from '../../Actions/useIdleTimeout';

const Travelogue = ({ phases, currentPhase, currentPhasePos, goToPhase, answers, progress, MovetoNextPhase, displayButton, setDisplayButton, currentSource, imgPos, matchingComplete, orderingComplete }) => {

    const params = new URLSearchParams(new URL(window.location.href).search);
    const quest = params.get("quest");
    const token = localStorage.getItem('token');

    const [content, setPhases] = useState([]);
    const [travelogueAnswers, setTravelogueAnswers] = useState({});
    const [error, setError] = useState('');
    const [textBoxes, setTextBoxes] = useState([]);
    const [currentPhaseReq, setCurrentPhaseReq] = useState({});
    const [skipButton, setSkipButton] = useState('hidden');

    //const [isTyping, setIsTyping] = useState(false);

    const isTypingRef = useRef(false);

    //const [displayPhaseButton, setPhaseButton] = useState('hidden');

    /* This is so many calls...
    const isIdle = useIdleTimeout(3000, () => {
        //5 seconds of idle
        //Save the travelogue to server after this idle timer
        userActions.UpdateTravelogue(token, quest, { answers: answers, progress: progress });
    });
    */

    const getPhases = () => {
        userActions.GetStudentQuestData(token, (quest_data, exhibit_data, student_data, class_data) => {
            if (class_data) {
                userActions.GetCurrentPhases(token, class_data.id, quest, (data) => {
                    if (data && currentPhasePos != -1) {
                        setCurrentPhaseReq(data[currentPhasePos]);
                        setSkipButton(data[currentPhasePos].is_skip ? '' : 'hidden');
                    }
                })
            }
        })
    }

    useEffect(() => {
        getPhases();
        setTravelogueAnswers({ answers: answers ? answers : {}, progress: progress ? progress : {} });
        setDisplayButton(currentPhase.category == 'read' ? true : false)
        let temp = answers ? answers : {};
        let progress_temp = progress ? progress : {};
        if (!progress_temp) {
            progress_temp = {};
        }
        if (Object.keys(temp).length < 1) {
            Object.keys(phases).map((value, key) => {
                temp[key] = {};
                progress_temp[key] = 0;
                switch (phases[value].category) {
                    case 'two_type':
                        temp[key][phases[value].type.split(' and ')[0]] = '';
                        temp[key][phases[value].type.split(' and ')[1]] = '';
                        break;
                    case 'three_type':
                        temp[key][phases[value].type.split(', ')[0]] = '';
                        temp[key][phases[value].type.split(', ')[1]] = '';
                        temp[key][phases[value].type.split(', ')[2]] = '';
                        break;
                    case 'poem':
                        temp[key]['Words'] = '';
                        temp[key]['Poem'] = '';
                        break;
                    case 'phrase':
                        temp[key]['Quote, Phrase, Fact'] = '';
                        temp[key]['Reason'] = '';
                        break;
                    case 'details':
                        temp[key]['Detail'] = '';
                        temp[key]['Perspective'] = '';
                        break;
                    case 'snapshot':
                        temp[key]['Source'] = '';
                        temp[key]['Answer'] = '';
                        break;
                    case 'teach_me_more':
                        temp[key]['Source'] = '';
                        temp[key]['Reason'] = '';
                        break;
                    case 'word_bank':
                        temp[key]['Bank'] = 'Suffrage, Suffragette, Amendment, Declaration, Barriers, Prejudice, Indigenous, Sovereignty, Executive, Judicial, Legislative';
                        temp[key]['Answer'] = '';
                        break;
                    case 'read':
                        temp[key]['Travelogue'] = '';
                        break;
                    case 'pick_phrases':
                        temp[key]['Pick'] = '';
                        break;
                    case 'traits':
                        temp[key]['Traits'] = '';
                        break;
                    case 'ordering':
                        temp[key]['Ordering'] = '';
                        break;
                    case 'matching':
                        temp[key]['Matching'] = '';
                        break;
                    default:
                        temp[key] = '';
                        break;
                }
            });
            setTravelogueAnswers({ progress: progress_temp, answers: temp });

        }

        RegenerateTextBoxes(temp);
        setPhases(phases);
    }, [phases, currentPhase, currentPhasePos, answers, progress]);

    const answer = (value, index) => {
        if (index) {
            answers[currentPhasePos][index] = value;
        }
        else {
            answers[currentPhasePos] = value;
        }

        isTypingRef.current = true;
        CheckAnswers(answers[currentPhasePos]);
    }

    const CheckAnswers = (answer) => {
        if (!answer) {
            answer = travelogueAnswers.answers[currentPhasePos];
        }

        let errormessage = null;

        //if the user is typing during ordering/matching phase, ensure no error is flagged (no text requirements)
        if ((currentPhase.category === "ordering" || currentPhase.category === "matching") && isTypingRef.current) {
            setError('');
            return true;
        }

        switch (currentPhase.category) {
            case 'three_type':
            case 'two_type':
            case 'letter':
            case 'story':
            case 'phrase':
            case 'details':
            case 'questions':
            case 'pick_phrases':
            case 'traits':
            case 'events':
                errormessage = CheckForSentences(answer);
                break;
            case 'word_bank':
                errormessage = CheckForSentences(answer.Answer);
                break;
            case 'teach_me_more':
                errormessage = CheckForSentences(answer.Reason, answer.Source, true);
                break;
            case 'snapshot':
                errormessage = CheckForSentences(answer.Answer, answer.Source, true);
                break;
            case 'poem':
                errormessage = CheckPoem(answer);
                break;
            case 'traits':
                errormessage = CheckTraits(answer);
            case 'ordering':
                //check if ordering activity is correct
                errormessage = orderingComplete ? '' : "All images must be put into the correct order before moving to the next phase.";
                setError(errormessage);
                break;
            case 'matching':
                //check if ordering activity is correct
                errormessage = matchingComplete ? '' : "All images must be matched to the correct source before moving to the next phase." + CheckForSentences(answer);
                setError(errormessage);
                break;
            default:
                break;
        }

        const result = errormessage ? false : true

        togglePhaseButton(result);
        return result;
    }

    const RegenerateTextBoxes = (answers) => {
        let temp = [];
        if (typeof answers[currentPhasePos] === 'object') {
            Object.keys(answers[currentPhasePos]).map((value, index) => {
                let travelogueLabel;
                switch (value) {
                    case 'Information':
                        travelogueLabel = 'Information'
                        break;
                    case 'Similarity':
                        travelogueLabel = 'Similarities'
                        break;
                    case 'Quote, Phrase, Fact':
                        travelogueLabel = 'Quote, Phrase, or Fact'
                        break;
                    case "Poem":
                        travelogueLabel = 'Poem'
                        break;
                    case 'Words':
                        travelogueLabel = 'Words'
                        break;
                    case 'Ordering':
                        travelogueLabel = 'Ordering'
                        break;
                    case 'Matching':
                        travelogueLabel = 'Matching'
                        break;
                    case 'Pick':
                        travelogueLabel = 'Pick'
                        break;
                    case 'Traits':
                        travelogueLabel = 'Traits'
                        break;
                    default:
                        travelogueLabel = value + 's';
                        break;
                }
                value == 'Travelogue' ?
                    currentPhasePos == 0 ?
                        temp.push(
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <label>Welcome to your Travelogue</label>
                                <p id='first-phase-text'>Click the "Next Phase" button to start the quest!</p>
                            </div>
                        )
                        :
                        temp.push(
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <label>Travelogue</label>
                                <textarea style={{ height: '80%' }} onChange={(e) => answer(e.target.value, value)} defaultValue={answers[currentPhasePos][value]} key={`${value}-${currentPhasePos}`}></textarea>
                            </div>
                        )
                : value == 'Pick Phrases' ?
                    temp.push(
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <label>{travelogueLabel}</label>
                            <textarea style={{ height: '80%' }} onChange={(e) => answer(e.target.value, value)} defaultValue={answers[currentPhasePos][value]} key={`${value}-${currentPhasePos}`}></textarea>
                        </div>
                    )
                : value == 'Traits' ?
                    temp.push(
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <label>{travelogueLabel}</label>
                            <textarea style={{ height: '80%' }} onChange={(e) => answer(e.target.value, value)} defaultValue={answers[currentPhasePos][value]} key={`${value}-${currentPhasePos}`}></textarea>
                        </div>
                    )
                : value == 'Matching' ?
                    temp.push(
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <label>{travelogueLabel}</label>
                            <textarea style={{ height: '80%' }} onChange={(e) => answer(e.target.value, value)} defaultValue={answers[currentPhasePos][value]} key={`${value}-${currentPhasePos}`}></textarea>
                        </div>
                    )
                :
                    temp.push(
                        <div >
                            <label>{travelogueLabel}</label>
                            {
                                value == 'Source' ?
                                    (answers[currentPhasePos][value]).split("   ").map((source) => {
                                        return (
                                            (currentSource[imgPos].type === 'Text' ?
                                                <p style={{ fontSize: '0.55vi', color: 'black' }}>{source}</p>
                                                :
                                                (currentSource[imgPos].type === 'Video' ?
                                                    <iframe src={source}></iframe>
                                                    :
                                                    <img style={{ width: 'auto', maxWidth: '75%', }} key={source} src={source}></img>
                                                )
                                            )
                                        )
                                    })
                                    :
                                    (currentPhase.category == "three_type" ?
                                        (
                                            <textarea className='studentInput three-type' onChange={(e) => answer(e.target.value, value)} defaultValue={answers[currentPhasePos][value]} key={`${value}-${currentPhasePos}`}></textarea>
                                        )
                                        : currentPhase.category == "ordering" ? (<></>)
                                        :
                                        (
                                            <textarea className='studentInput' onChange={(e) => answer(e.target.value, value)} defaultValue={answers[currentPhasePos][value]} key={`${value}-${currentPhasePos}`}></textarea>
                                        )

                                    )
                            }
                        </div>
                    )
            })
        }
        else {
            if (currentPhasePos == 0) {
                temp.push(
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <label>Welcome to your Travelogue</label>
                        <p id='first-phase-text'>Click the "Next Phase" button to start the quest!</p>
                    </div>
                )
            } else {
                temp.push(
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <label>Travelogue</label>
                        <textarea style={{ height: '80%' }} onChange={(e) => answer(e.target.value, null)} defaultValue={answers[currentPhasePos]} key={`travelogue-${currentPhasePos}`}></textarea>
                    </div>
                )
            }

        }
        setTextBoxes(temp);
    }

    const CheckForSentences = (sentencesArray, source, has_source) => {
        let temp = ''
        Object.keys(sentencesArray).map((value) => {
            temp += sentencesArray[value];
        })

        const sentences = IndividualSentences(temp);

        // const numberOfLineBreaks = (temp.match(/\n/g) || []).length; REMOVED BECAUSE NOT CHECKING SOURCE COUNT ANYMORE.

        let errormessage = ''
        const uppercaseErrorCount = CheckProperSentences(sentences);
        errormessage += uppercaseErrorCount ? '(' + uppercaseErrorCount + ') sentences do not start with a Capitol Letter.' : '';

        // Source Check
        if (has_source) {
            if (!source) {
                errormessage += "\n Need a snapshot."
            }
        }

        // Senetence Amount Check
        if (sentences.length < currentPhaseReq.sentence_limit) {
            errormessage += "\n Need (" + ((currentPhaseReq.sentence_limit) - sentences.length) + ') more valid sentences.'
        }

        setError(errormessage);
        return errormessage;
    }

    const CheckProperSentences = (sentences) => {
        let uppercaseErrorCount = 0

        for (let x = 0; x < sentences.length - 1; x++) {
            const StartingCharacter = sentences[x][0];
            if (!StartingCharacter) {
                uppercaseErrorCount++;
            }
            else if (StartingCharacter !== StartingCharacter.toUpperCase()) {
                uppercaseErrorCount++;
            }
        }
        return uppercaseErrorCount;
    }

    const CheckPoem = (input) => {
        const sentences = IndividualSentences(input.Poem);
        const wordsCount = IndividualWords(input.Poem);
        let useWords = IndividualWords(input.Words);
        let nonUsedWords = [];

        let errormessage = '';
        let uppercaseErrorCount = 0;

        for (const x in wordsCount) {
            wordsCount[x] = wordsCount[x].toLowerCase();
        }

        for (let x = 0; x < useWords.length; x++) {
            const word = useWords[x].toLowerCase().replace(" ", "")
            if (word && !wordsCount.includes(word)) {
                nonUsedWords.push(useWords[x].replace(" ", ""))
            }
        }

        for (let x = 0; x < sentences.length - 1; x++) {
            const StartingCharacter = sentences[x][0];
            if (!StartingCharacter) {
                uppercaseErrorCount++;
            }
            else if (StartingCharacter !== StartingCharacter.toUpperCase()) {
                uppercaseErrorCount++;
            }
        }
        errormessage += uppercaseErrorCount ? '(' + uppercaseErrorCount + ') sentences do not start with a Capitol Letter.' : '';

        if (wordsCount.length < 20) {
            errormessage += "\n You need at least " + (20 - wordsCount.length) + " words."
        }

        if (nonUsedWords.length > 0) {
            errormessage += "\n These words are missing: "
            for (const x in nonUsedWords) {
                errormessage += nonUsedWords[x] + (x == nonUsedWords.length - 1 ? '' : ', ');
            }
        }

        setError(errormessage);
        return errormessage;
    }

    const CheckTraits = (input) => {
        const sentences = IndividualSentences(input);
        const wordCount = IndividualWords(input);

        let errormessage = '';
        let uppercaseErrorCount = 0;

        for (let x = 0; x < sentences.length - 1; x++) {
            const StartingCharacter = sentences[x][0];
            if (!StartingCharacter) {
                uppercaseErrorCount++;
            }
            else if (StartingCharacter !== StartingCharacter.toUpperCase()) {
                uppercaseErrorCount++;
            }
        }
        errormessage += uppercaseErrorCount ? '(' + uppercaseErrorCount + ') sentences do not start with a Capitol Letter.' : '';

        if (wordCount.length < 5) {
            errormessage += `\n You need ${5 - wordCount.length} more words or sentences.`
        }

        setError(errormessage);
        return errormessage;
    }

    const IndividualWords = (text) => {
        const wordsCount = text.split(/[\s\p{P}]+/u);
        let temp = [];
        for (const x in wordsCount) {
            if (wordsCount[x]) {
                temp.push(wordsCount[x])
            }
        }
        return temp;
    }

    const IndividualSentences = (text) => {
        const sentences = text.split(/[.!?]+/).filter(sentence => sentence.trim() !== "");
        return sentences;
    }

    const TryMoveNextPhase = () => {
        isTypingRef.current = false;
        const result = CheckAnswers(null);
        if (result) {
            MovetoNextPhase(error);
            switch (currentPhase.category) {
                case 'ordering':
                    answer("Done", "Ordering");
                    break;
            }
            setDisplayButton('hidden');
        }

        userActions.UpdateTravelogue(token, quest, { answers: answers, progress: progress })
    }

    const SkipPhase = () => {
        MovetoNextPhase(error);
        setDisplayButton('hidden');
        userActions.UpdateTravelogue(token, quest, { answers: answers, progress: progress })
    }

    const togglePhaseButton = (display) => {
        setDisplayButton(display ? '' : 'hidden');
    }

    return (
        <Fragment>
            <div className={'full full-travelogue'} >
                <div id='inner_container' className='travelogue-shadow' >
                    {error != '' && <h2 style={{ position: 'absolute', margin: '-10% 0% 0% 0%', color: 'red', fontWeight: 'bold', fontSize: '1.1vi' }}>{error}</h2>}

                    <div className={`travelogue-tabs ${currentPhase.category == 'three_type' ? 'three-type-tabs' : ''}`}>
                        {Object.keys(content).map((value, key) => {
                            const place = Number(value.split('_')[1]);
                            if (place == 1 || progress[place - 1] == 1 || progress[place - 2] == 1 || progress[place + 1] == 1) {
                                return (
                                    <div id={value} key={value} className={`travelogue-tab${progress[place - 1] == 1 ? '2' : ''} ${key == currentPhasePos ? 'tab-active' : ''} `} style={{ width: `${key <= 8 ? 35 - (key) : 33}px`, transform: `translateX(${key <= 6 ? 1 - key : -5}px)` }} onClick={(e) => { goToPhase(content[value], key); setError(''); }}>
                                        <p style={{ margin: 'auto' }}>{key + 1}</p>
                                    </div>
                                )
                            }
                        }
                        )}
                    </div>
                    {
                        currentPhase.category == "three_type" ?
                            <div className='travelogue-textarea-shadow three-type' >
                                {textBoxes}
                            </div>
                            :
                            <div className='travelogue-textarea-shadow' >
                                {textBoxes}
                            </div>
                    }

                </div>
                <div>
                    <button className={`skip-phase ${skipButton}`} onClick={() => SkipPhase()}>Skip</button>
                    <button className={`next-phase ${displayButton}`} onClick={() => TryMoveNextPhase()}>Next Phase</button>
                </div>
            </div>

        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Travelogue);
export { connection as Travelogue };