import React from 'react';
import ReactDOM from 'react-dom/client';

import './css/index.css';
import './css/alert.css';
import './css/template.css';
import './css/activity.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-circular-progressbar/dist/styles.css';

import App from './App/App';

import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
import rootReducer from './Redux/index';

//Redux
const store = configureStore({
  reducer: rootReducer,
});
export { store };

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

