//--------------------------------------------------
//Quest Page for Teachers
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from "../../Actions";
import { DashboardNav } from "../GeneralComponents";

const QuestList = ({ }) => {

    const params = new URLSearchParams(new URL(window.location.href).search);
    const classroom_id = params.get("classroom");
    const token = localStorage.getItem('token');

    const [quests, setQuests] = useState([]);
    const [currentQuest, setCurrentQuest] = useState({});
    const [currentQuestButton, setCurrentQuestButton] = useState(null);
    const [currentPhases, setCurrentPhases] = useState([]);
    const [currentPhaseTypes, setCurrentPhaseTypes] = useState([]);
    const [animatePanel, setAnimation] = useState('animateFromRight');

    const [tempFirstToggle, setTemp] = useState('purple')
    const [newSentenceRequirements, setNewSentenceRequirements] = useState({});

    useEffect(() =>
        userActions.GetActiveQuests(token, classroom_id, (data) => {
            let questList = data.quests;
            for (const x in questList) {
                questList[x].locked = data.locked[x].is_locked;
            }
            ToggleAnimation();
            if (questList) {
                setQuests(questList);
                setCurrentQuest(questList[0]);
            }
        }), []);

    useEffect(() => {
        if (currentQuest.id) {
            userActions.GetCurrentPhases(token, classroom_id, currentQuest.id, (data) => {
                setCurrentPhases(data);
            });
            const phaseTypes = Object.values(currentQuest.content)
                .map(phase => phase.type)
                .filter(type => type !== undefined);
            setCurrentPhaseTypes(phaseTypes);
        }
    }, [currentQuest]);

    const changeQuest = (quest, target) => {
        if (quest.id == currentQuest.id) {
            return;
        }

        userActions.GetCurrentPhases(token, classroom_id, quest.id, (data) => {
            setCurrentPhases(data);
        })

        setTemp('');

        if (currentQuestButton) {
            currentQuestButton.className = '';
        }
        target.className = 'purple';
        setCurrentQuestButton(target);

        setCurrentQuest(quest);
        ToggleAnimation();
        return 'purple';
    }

    const ToggleAnimation = () => {
        setAnimation('animateFromRight');
        setTimeout(() => {
            setAnimation('');
        }, 750)
    }

    const toggleQuestLock = (quest) => {
        userActions.ToggleLockQuest(token, classroom_id, quest, (data) => {
            if (data) {
                let temp = currentQuest;
                temp.locked = data.lock_status;
                //needs ... so that it is a new array, not reference
                setCurrentQuest({ ...temp });
            }
            else {
                console.log('Could not toggle quest at this time')
            }
        })
    }

    const handleNewSentenceRequirementChange = (e, phaseId) => {
        setNewSentenceRequirements({
            ...newSentenceRequirements,
            [phaseId]: e.target.value,
        });
    };

    const togglePhaseSkip = (phase) => {
        let formData = { "type": "skip", "sentence_limit": phase.sentence_limit }
        userActions.UpdatePhaseRequirements(token, phase.classroom_to_quest_id, phase.phase_id, formData, () => {
            userActions.GetCurrentPhases(token, classroom_id, currentQuest.id, (data) => {
                setCurrentPhases(data);
            })
        })
    }

    const toggleNewRequirements = (phase, number) => {
        let formData = { "type": "sentence", "sentence_limit": number }
        userActions.UpdatePhaseRequirements(token, phase.classroom_to_quest_id, phase.phase_id, formData, () => {
            userActions.GetCurrentPhases(token, classroom_id, currentQuest.id, (data) => {
                setCurrentPhases(data);
            })
        })
    }

    return (
        <Fragment>
            <div className='quest-list'>

                <DashboardNav />
                <div className={'classlist'}>
                    <h1 className='sectionHeader grid-col-1'>Quest Dashboard</h1>
                    <div className='questsContainer overflow-x-clip'>
                        <div className='quests'>
                            {quests?.map((quest, index) => {
                                return (
                                    <div className={index == 0 ? tempFirstToggle : ''} onClick={(e) => changeQuest(quest, e.target)} key={'quest ' + quest.id}>
                                        {quest.name}
                                    </div>
                                )
                            })}
                        </div>
                        <div className={`questInfo ${animatePanel}`}>
                            <h1>Quest Information</h1>
                            <div className='questContent'>
                                <h2>{currentQuest.name}</h2>
                                <hr></hr>
                                <div className='stat'>
                                    Quest Duration: <span>{currentQuest.duration}</span>
                                </div>
                                <div className='stat'>
                                    Quest Summary: <br></br><span>{currentQuest.description}</span>
                                </div>
                                <div className='stat inline-flex'>
                                    Lock Quest:
                                    <label className="switch">
                                        <input type="checkbox" onChange={(e) => toggleQuestLock(currentQuest.id, e.target)} checked={currentQuest.locked == 1 ? true : false}></input>
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='quest-requirements'>
                    <h1>Quest Requirements - Quest {currentQuest.id}</h1>
                    {currentPhases.length > 0 && currentPhases[0].phase_id &&
                        currentPhases.map((phase) => (
                            currentPhaseTypes[phase.phase_id-1] === 'Reading' ? <></> 
                            :
                            <div className='phase' key={phase.phase_id}>
                                <div className='phase-title'>
                                    <h2>Phase {phase.phase_id}: </h2>
                                    <h3>{currentPhaseTypes[phase.phase_id-1]}</h3>
                                </div>
                                <div>
                                    <p>Skip</p>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            onChange={(e) => togglePhaseSkip(phase, e.target)}
                                            checked={phase.is_skip == 1 ? true : false}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                {currentPhaseTypes[phase.phase_id-1] === 'Ordering' 
                                || currentPhaseTypes[phase.phase_id-1] === 'Poem'
                                || currentPhaseTypes[phase.phase_id-1] === 'Matching' ? <></>
                                :
                                    <div className='sentence-req-container'>
                                        <div className='sentence-requirement'>
                                            <p>Current <br /> Sentence Requirement</p>
                                            <input disabled={true} value={phase.sentence_limit}></input>
                                        </div>
                                        <div className='sentence-requirement'>
                                            <p>New <br /> Sentence Requirement</p>
                                            <input disabled={phase.is_skip == 1 ? true : false} value={newSentenceRequirements[phase.phase_id] || ''} onChange={(e) => handleNewSentenceRequirementChange(e, phase.phase_id)}></input>
                                        </div>
                                        <button className='purple' onClick={() => toggleNewRequirements(phase, newSentenceRequirements[phase.phase_id] || 0)}>Confirm</button>
                                    </div>
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(QuestList);
export { connection as QuestList };