//--------------------------------------------------
//Defines user requests to interact with the server and sends to redux
//--------------------------------------------------

import { sessionConstants } from "../Redux/constants";
import env from "react-dotenv";
import { alertActions } from "../Actions";
import { store } from '../index'

const API = env?.API || "https://idagem.org/api/"

//const API = "http://localhost:5000/api/"

//"https://lunarpenguin.net/idagem-server/api/" //s//URL of server if in production

export const userActions = {
    // GENERAL
    reroute,

    // EMAIL
    SendVerificationEmail,
    SendResetEmail,
    CheckResetCode,

    // ACCOUNT
    RegisterTeacher,
    RegisterStudent,
    RegisterStudentAsTeacher,
    LoginTeacher,
    LoginStudent,
    EditAccount,
    ResetPassword,
    DeleteAccount,
    Logout,
    FindUserById,
    GetStudentLinkedToTeacher,
    GetUserData,
    GetSchoolList,

    // ICONS
    GetIcon,
    UpdateIcon,
    GetExhibitImage,

    // CLASSROOMS
    CreateClassroom,
    GetClassroomName,
    GetClassroomStudents,
    GetClassroomList,
    EditClassroom,
    RemoveStudent,
    DeleteClassroom,
    RefreshClassroomCode,

    // QUESTS
    GetQuestContent,
    GetActiveQuests,
    GetCurrentPhases,
    GetStudentQuestData,
    GetTravelogue,
    GetStudentTravelogue,
    GetStudentExhibits,
    ToggleLockQuest,
    UpdateTravelogue,
    UpdatePhaseRequirements,
    ApproveContent,
    DenyContent
}

//------------------------------
// GENERAL
//------------------------------

function redux(type, info) {
    return { type: type, info };
}

function reroute(path) {
    window.location.assign(`${window.location.origin}/${path}`);
}

function handleApiRequest(endpoint, method, body, onApiSuccess, headers, stringifyBody) {
    return (dispatch) => {
        dispatch(redux(sessionConstants.REQUEST, endpoint));
        dispatch(alertActions.Clear());
        fetch(API + endpoint, {
            method: method,
            headers: !headers ? { "Content-Type": "application/json" } : headers,
            body: body && !stringifyBody ? JSON.stringify(body) : body,
        })
            .then((res) => {
                if (res.status === 429) {
                    dispatch(alertActions.Error("Too many requests. Please try again later."));
                    throw new Error('Too many requests');
                }
                return res.json();
            })
            .then((data) => {
                const { err } = data;
                if (err) {dispatch(alertActions.Error(err)) } 
                if (typeof onApiSuccess === 'function') onApiSuccess(data, dispatch)
            })
            .catch((err) => console.log("There was an error in the request", err));
    };
}

//------------------------------
// EMAIL
//------------------------------

function SendVerificationEmail(token) {
    return handleApiRequest("SendVerificationEmail", "POST", { token }, (data, dispatch) => {
        const { message } = data;
        if (message) dispatch(alertActions.Success(message))
    })
}

function SendResetEmail({ email }) {
    return handleApiRequest("SendResetEmail", "POST", { email }, (data, dispatch) => {
        const { message } = data;
        if (message) {
            dispatch(alertActions.Success(message));
            dispatch(redux(sessionConstants.ResetEmailSuccess, ""))
        }
    })
}

function CheckResetCode({ code, email, captchaToken }) {
    return handleApiRequest("CheckResetCode", "POST", { code, email, captchaToken }, (data, dispatch) => {
        const { message, accessToken } = data;
        if (message) {
            dispatch(alertActions.Success(message));
            if (data.accessToken) dispatch(redux(sessionConstants.CheckResetCodeSuccess, accessToken))
        }
    })
}

//------------------------------
// ACCOUNT
//------------------------------

function RegisterTeacher({ first_name, last_name, email, password, school }) {
    return handleApiRequest("Register/Teacher", "POST", { first_name, last_name, email, password, school, 'status': 'teacher', }, (data, dispatch) => {
        const { message, user, enableCaptcha } = data;
        if (message) {
            dispatch(redux(sessionConstants.LoginSuccess, user))
            dispatch(alertActions.Success(message));
            setTimeout(() => reroute("dashboard"), 750);
        }
    })
}

function RegisterStudent({ student_id, classroom_code }) {
    return handleApiRequest("Register/Student", "POST", { student_id, classroom_code, 'status': 'student', 'type': 'register' }, (data, dispatch) => {
        const { message, user, enableCaptcha } = data;
        if (message) {
            dispatch(redux(sessionConstants.LoginSuccess, user))
            dispatch(alertActions.Success(message));
            setTimeout(() => reroute("game/"), 750);
        }
    })
}

function RegisterStudentAsTeacher(id, first_name, last_name, classroom_code) { 
    const requestBody = { 'id': id, 'first_name': first_name, 'last_name': last_name, 'classroom_code': classroom_code, 'status': 'student', 'type': 'register' };
    fetch(API + 'Register/StudentAsTeacher', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody)
    })
    .then((res) => {
        if (!res.ok) {
            store.dispatch(alertActions.Error('Invalid class code!'));
        }
        else {
            store.dispatch(alertActions.Success('Successfully registered student as teacher!'));
            setTimeout(() => reroute("game/"), 750);
        }
    })
    .catch((error) => { 
        store.dispatch(alertActions.Error(error));
    });
}

function LoginTeacher({ email, password, useCaptcha, captchaToken }) {
    const requestData = { email, password, useCaptcha, 'status': 'teacher', };
    if (useCaptcha) { requestData.captchaToken = captchaToken }
    return handleApiRequest("Login/Teacher", "POST", requestData, (data, dispatch) => {
        const { message, user, enableCaptcha } = data;
        if (message && user) {
            dispatch(redux(sessionConstants.LoginSuccess, user))
            dispatch(alertActions.Success(message));
            setTimeout(() => reroute("dashboard"), 750);
        }
        if (enableCaptcha != null) {
            dispatch(redux(sessionConstants.UseCaptcha, enableCaptcha))
        }
    })
}

function LoginStudent({ username, student_id, classroom_code, useCaptcha, captchaToken }) {
    const requestData = { username, student_id, classroom_code, useCaptcha, 'status': 'student', 'type': 'login' };
    if (useCaptcha) { requestData.captchaToken = captchaToken }
    return handleApiRequest("Login/Student", "POST", requestData, (data, dispatch) => {
        const { message, user, enableCaptcha } = data;
        if (message && user) {
            dispatch(redux(sessionConstants.LoginSuccess, user))
            dispatch(alertActions.Success(message));
            setTimeout(() => reroute("game/"), 750);
        }
        if (enableCaptcha != null) {
            dispatch(redux(sessionConstants.UseCaptcha, enableCaptcha))
        }
    })
}

function EditAccount(token, first_name, last_name, school, email, password, onComplete) {
    const form = new FormData();
    form.append('first_name', first_name);
    form.append('last_name', last_name);
    form.append('school', school);
    form.append('email',  email);
    form.append('password', password);

    return handleApiRequest("EditAccount", "PUT", form, (data, dispatch) => {
        const { message, changedEmail } = data;
        if (message) dispatch(alertActions.Success(message));

        if (changedEmail) {
            Logout()
        } else {
            onComplete()
        }
    }, {"Authorization": token}, true )
}

function ResetPassword({ accessToken, password }) {
    return handleApiRequest("ResetPassword", "PUT", { accessToken, password }, (data, dispatch) => {
        const { message } = data;
        if (message) {
            dispatch(alertActions.Success(message));
            setTimeout(() => reroute("Login"), 750);
        }
    })
}

function DeleteAccount(token, id) {
    return handleApiRequest("DeleteAccount", "DELETE", { token, id }, (data, dispatch) => {
        const { message } = data;
        if (message) {
            dispatch(alertActions.Success(message));
            dispatch(redux(sessionConstants.Logout, "Logout"))
            reroute("Login")
        }
    })
}

function Logout() {
    return (dispatch) => {
        dispatch(redux(sessionConstants.Logout, "Logout"))
        reroute("Login")
    }
}

function FindUserById(token, id) {
    return handleApiRequest("FindUserById", "POST", { token, id }, (data, dispatch) => {
        const { message, userById } = data;
        if (userById[0]) {
            dispatch(redux(sessionConstants.FindUserSuccess, userById[0]))
        }
    })
}

function GetStudentLinkedToTeacher(id, onComplete) {
    store.dispatch(alertActions.Clear());
    fetch(API + 'GetStudentLinkedToTeacher', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id: id })
    })
    .then((res) => res.json()) 
    .then((data) => {
        onComplete(data);
    })
    .catch((error) => {
        console.error("Error fetching student data:", error);
        onComplete(null); 
    });
}

function GetUserData(token, onComplete) {
    store.dispatch(alertActions.Clear());
    fetch(API + 'user/data', {
        method: 'GET',
        headers: { "Content-Type": "application/json", "Authorization": token },
    })
    .then((res) => res.json())
    .then((data) => {
        onComplete(data);
    })
    .catch((err) => {
        store.dispatch(alertActions.Error(err));
    });
}

function GetSchoolList(state, onComplete) {
    store.dispatch(alertActions.Clear());
    fetch(API + 'list/schools/' + state)
    .then((res) => res.json())
    .then((data) => {
        onComplete(data.data);
    })
    .catch((err) => {
        store.dispatch(alertActions.Error(err));
    });
}

//------------------------------
// ICONS
//------------------------------

function GetIcon(id, onComplete) {
    store.dispatch(alertActions.Clear());
    if(id) {
        fetch(API + "icon/" + id, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        })
        .then((res) => res.json()) 
        .then((data) => {
            onComplete(data);
        })
        .catch((err) => console.log("There was an error in the request: Get Icon: ", err));
    }
}

function UpdateIcon(formData, id, onComplete) {
    store.dispatch(alertActions.Clear());
    fetch(API + 'icon/' + id, {
        method: 'POST',
        body: formData,
    })
    .then((res) => res.json()) 
    .then(() => {
        store.dispatch(alertActions.Success("Sucessfully updated icon."));
        onComplete();
    })
    .catch((err) => {
        store.dispatch(alertActions.Error(err));
    });
}

function GetExhibitImage(id, key, onComplete) {
    store.dispatch(alertActions.Clear());
    if(key) {
        fetch(API + "exhibit/image/" + id + "/" + key, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((res) => res.json()) 
        .then((data) => {
            onComplete(data);
        })
        .catch((err) => console.log("There was an error in the request: Get Exhibit Image: ", err));
    }
}

//------------------------------
// CLASSROOMS
//------------------------------

function CreateClassroom(className, grade, icon, token, onComplete) {
    store.dispatch(alertActions.Clear());
    const form = new FormData();
    form.append('icon', icon);
    form.append('grade', grade);
    form.append('name', className);

    fetch(API + 'create/classroom', {
        method: 'PUT',
        headers: { //"Content-Type": "application/json", 
            "Authorization": token },
        body: form,
    })
    .then((res) => res.json())
    .then((data) => {
        if (data.err) {
            store.dispatch(alertActions.Error(data.err));
        }
        else {
            store.dispatch(alertActions.Success("Classroom succesfully created."));
            onComplete();
        }
    })
}

function GetClassroomName(classroom_code, onComplete) {
    store.dispatch(alertActions.Clear());
    fetch(API + "name/classroom/" + classroom_code, {
        method: 'GET',
        headers: { "Content-Type": "application/json" },
    })
    .then((res) => res.json()) 
    .then((data) => {
        onComplete(data);
    })
    .catch((err) => {
        store.dispatch(alertActions.Error(err));
    });
}

function GetClassroomStudents(classroom_id, token, onComplete) {
    store.dispatch(alertActions.Clear());
    fetch(API + 'list/classroom/' + classroom_id + '/students', {
        method: 'GET',
        headers: { "Content-Type": "application/json", "Authorization": token },
    })
    .then((res) => res.json()) 
    .then((data) => {
        onComplete(data);
    })
    .catch((err) => {
        store.dispatch(alertActions.Error(err));
    });
}

function GetClassroomList(token, onComplete) {
    fetch(API + 'list/classrooms', {
        method: 'GET',
        headers: { "Content-Type": "application/json", "Authorization": token },
    })
    .then((res) => res.json())
    .then((data) => {
        onComplete(data);
    })
    .catch((err) => {
        store.dispatch(alertActions.Error(err));
    });
}

function EditClassroom(className, id, grade, icon, onChange, token, onComplete) {
    store.dispatch(alertActions.Clear());
    let formData = new FormData();
    formData.append("id", id);
    formData.append("name", className);
    formData.append("grade", grade);
    if (icon) formData.append("icon", icon);
    formData.append("onChange", onChange);
    fetch(API + 'edit/classroom', {
        method: 'PUT',
        headers: { "Authorization": token },
        body: formData,
    })
    .then((res) => res.json())
    .then((data) => {
        if (data.err) {
            window.alert(data.err);
        }
        else {
            store.dispatch(alertActions.Success("Classroom succesfully edited."));
            onComplete();
        }
    })
}

function RemoveStudent(studentID, class_id, token, onComplete) {
    store.dispatch(alertActions.Clear());
    fetch(API + 'remove/student/' + studentID + '/classroom/' + class_id, {
        method: 'PUT',
        headers: { "Content-Type": "application/json", "Authorization": token },
    })
    .then((res) => res.json()) 
    .then(() => {
        store.dispatch(alertActions.Success("Sucessfully removed student."));
        onComplete();
    })
    .catch((err) => {
        store.dispatch(alertActions.Error(err));
    });
}

function DeleteClassroom(class_id, token, onComplete) {
    store.dispatch(alertActions.Clear());
    fetch(API + 'delete/classroom/' + class_id, {
        method: 'PUT',
        headers: { "Authorization": token },
    })
    .then((res) => res.json())
    .then(() => {
        store.dispatch(alertActions.Success("Classroom succesfully deleted."));
        onComplete();
     })
     .catch((err) => {
        store.dispatch(alertActions.Error(err));
    });
}

function RefreshClassroomCode(classroom_id, token, onComplete) {
    store.dispatch(alertActions.Clear());
    fetch(API + 'refresh/classroom', {
        method: 'PUT',
        headers: { "Content-Type": "application/json", "Authorization": token },
        body: JSON.stringify({ 'classroom_id': classroom_id }),
    })
    .then((res) => res.json())
    .then((data) => {
        onComplete(data);
    })
    .catch((err) => {
        store.dispatch(alertActions.Error(err));
    });
}

//------------------------------
// QUESTS
//------------------------------

function GetQuestContent(quest_id, token, onComplete) {
    fetch(API + 'quests/' + quest_id, {
        method: 'GET',
        headers: { "Authorization": token },
    })
    .then((res) => res.json())
    .then((data) => {
        onComplete(data);
    })
    .catch((err) => {
        store.dispatch(alertActions.Error(err));
    });
}

function GetActiveQuests(token, class_id, onComplete) {
    fetch(API + 'list/quests/' + class_id, {
        method: 'GET',
        headers: { "Content-Type": "application/json", "Authorization": token },
    })
    .then((res) => res.json())
    .then((data) => {
        onComplete(data);
    })
    .catch((err) => {
        store.dispatch(alertActions.Error(err));
    });
}

function GetCurrentPhases(token, class_id, quest_id, onComplete) {
    fetch(API + 'list/phases/' + class_id + '/' + quest_id, {
        method: 'GET',
        headers: { "Content-Type": "application/json", "Authorization": token },
    })
    .then((res) => res.json())
    .then((data) => {
        onComplete(data);
    })
    .catch((err) => {
        store.dispatch(alertActions.Error(err));
    });
}

function GetStudentQuestData(student_id, token, onComplete) {
    fetch(API + 'student/quest/' + student_id, {
        method: 'GET',
        headers: { 'content-type': 'application/json', Authorization: token },
    })
    .then((res) => res.json())
    .then((data) => {
        onComplete(data.quest_data, data.exhibit_data, data.student_data, data.class_data);
    })
    .catch((err) => {
        store.dispatch(alertActions.Error(err));
    });
}

function GetTravelogue(token, quest_id, onComplete) {
    fetch(API + 'student/travelogue/' + quest_id, {
        method: 'GET',
        headers: { 'content-type': 'application/json', Authorization: token },
    })
    .then((res) => res.json())
    .then((data) => {
        onComplete(data.answers, data.progress);
    })
    .catch((err) => {
        store.dispatch(alertActions.Error(err));
    });
}

function GetStudentTravelogue(student_id, approvals, token, onComplete) {
    fetch(API + 'list/travelogue/' + student_id + '/' + approvals,  {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', "authorization": token },
    })
    .then((res) => res.json())
    .then((data) => {
        onComplete(data);
    })
    .catch((err) => {
        store.dispatch(alertActions.Error(err));
    });
}

function GetStudentExhibits(student_id, approvals, token, onComplete) {
    fetch(API + 'list/exhibits/' + student_id + '/' + approvals, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', "authorization": token },
    })
    .then((res) => res.json())
    .then((data) => {
        onComplete(data);
    })
    .catch((err) => {
        store.dispatch(alertActions.Error(err));
    });
}

function ToggleLockQuest(token, class_id, quest_id, onComplete) {
    fetch(API + 'lock/quest/'+ class_id + '/' + quest_id, {
        method: 'PUT',
        headers: { "Content-Type": "application/json", "Authorization": token },
    })
    .then((res) => res.json())
    .then((data) => {
        onComplete(data);
    })
    .catch((err) => {
        store.dispatch(alertActions.Error(err));
    });
}

function UpdateTravelogue(token, quest_id, formData) {
    fetch(API + 'student/update-travelogue/' + quest_id, {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: token },
        body: JSON.stringify(formData),
    })
    .then((res) => {
        return res.json();
    });
}

function UpdatePhaseRequirements(token, quest_id, phase_id, formData, onComplete) {
    fetch(API + 'phase/update-requirements/' + quest_id + '/' + phase_id, {
        method: 'POST',
        headers: { "Content-Type": "application/json", "Authorization": token },
        body: JSON.stringify(formData),
    })
    .then((res) => res.json())
    .then(() => {
        onComplete();
    })
    .catch((err) => {
        store.dispatch(alertActions.Error(err));
    });
}

function ApproveContent(student_id, quest_id, phase_id, type, token, onComplete) {
    store.dispatch(alertActions.Clear());
    let formData = { "student_id": student_id, "quest_id": quest_id, "phase_id": phase_id, "type": type }
    fetch(API + 'approve', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', "authorization": token },
        body: JSON.stringify(formData)
    })
    .then((res) => res.json())
    .then((data) => {
        store.dispatch(alertActions.Success(data));
        onComplete();
    })
    .catch((err) => {
        store.dispatch(alertActions.Error(err));
    });
}

function DenyContent(student_id, quest_id, phase_id, type, token, onComplete) {
    store.dispatch(alertActions.Clear());
    let formData = { "student_id": student_id, "quest_id": quest_id, "phase_id": phase_id, "type": type }
    fetch(API + 'deny', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', "authorization": token },
        body: JSON.stringify(formData)
    })
    .then((res) => res.json())
    .then((data) => {
        store.dispatch(alertActions.Success(data));
        onComplete();
    })
    .catch((err) => {
        store.dispatch(alertActions.Error(err));
    });
}