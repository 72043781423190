//--------------------------------------------------
//Redux constants for reducers and actions
//--------------------------------------------------

//User management
export const sessionConstants = {
    LoginSuccess: "LoginSuccess",
    RegisterSuccess: "RegisterSuccess",
    ResetEmailSuccess: "ResetEmailSuccess",
    CheckResetCodeSuccess: "CheckResetCodeSuccess",
    FindUserSuccess: "FindUserSuccess",
    UseCaptcha: "UseCaptcha",
    Logout: "Logout",

    REQUEST: "Default LOG",
}

//Page alerts
export const alertConstants = {
    SUCCESS: "Action SUCCESS",
    WARNING: "Action WARNING",
    ERROR: "Action DANGER",
    CLEAR: "Action CLEAR",
    Loading: "Action LOADING"
}