import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TTSButton } from '../GeneralComponents';

function Matching({ message, title, content, backgroundImage, togglePhaseButton, isFinished, setPhaseComplete }) {

    const [newOrder, setNewOrder] = useState({});
    const [staticFiles, setStaticFiles] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);

    function handleOnDrag(e, widgetType) {
        e.dataTransfer.setData("widgetType", widgetType);
    }

    function handleOnDrop(e) {
        const widgetType = e.dataTransfer.getData("widgetType");
        if (/*(e.target.children[1] && !e.target.classList.contains('bank')) ||*/ e.target.nodeName == 'IMG' || e.target.classList.contains('item')) {
            console.log(e.target.nodeName == 'IMG');
            console.log(e.target.classList.contains('ordering-item'));
            return;
        }
        e.target.append(document.getElementById(widgetType))
    }

    function handleDragOver(e) {
        e.preventDefault();
    }

    useEffect(() => {
        togglePhaseButton(false);
        let temp = {}
        let temp2 = [];
        let usedNumbers = [];
        const RandomSlot = () => {
            const randomNum = Math.ceil(Math.random() * (content.length / 2));
            if (usedNumbers.includes(randomNum)) {
                return RandomSlot();
            }
            usedNumbers.push(randomNum);
            return randomNum;
        }
        for (const x in content) {
            if (x % 2 == 0) {
                temp[RandomSlot()] = {
                    file: content[x].file,
                    citation: content[x].citation,
                    correct_slot: Number(x) == 0 ? Number(x) + 1 : Math.ceil((Number(x) + 1) / 2)
                }
            }
            else {
                temp2.push(content[x])
            }
        }
        setNewOrder(temp);
        setStaticFiles(temp2)
    }, [content])

    useEffect(() => {
        if(isFinished){
            setPhaseComplete(true);
        }
    })

    const CheckAnswers = () => {
        let errors = 0;
        const boxes = document.getElementsByClassName('box');
        //console.log(newOrder)
        Object.keys(boxes).map((a) => {
            const slot = boxes[a].id.split('_')[1];
            if (boxes[a].children[1] && slot == newOrder[Number(boxes[a].children[1].id)].correct_slot) {
                boxes[a].style.border = 'dashed green 2px'
            }
            else {
                boxes[a].style.border = 'dashed red 2px'
                errors++;
            }
        })

        if (errors < 1) {
            togglePhaseButton(true);
            setPhaseComplete(true);
        }
    }

    // console.log(widgets)
    // console.log(content);

    return (
        <>
            <TTSButton isActive={message != "" ? true : false} utterance={title + " ... " + message} isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
            <div className='full' style={{ backgroundSize: 'cover', backgroundColor: "#D69A87", padding: "2%" }} id='sources'>
                <h2 className='grid-col-12 quest-description'><strong>{title}</strong><br />{message}</h2>
                <div className='content-and-bank'>
                    <div className='matching'>
                        {staticFiles.map((value, index) => {
                            return (
                                <div>
                                    <TTSButton isActive={message != "" ? true : false} utterance={value.file} isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
                                    <div>
                                        <p className='matchingText'>{value.file}</p>

                                        {/* <hr style={{height: '5px', width: '10%', margin: 'auto'}}></hr> */}

                                      <div id={'box_' + (index + 1)} className='box' key={index + 'box'}  onDragOver={handleDragOver} onDrop={handleOnDrop}>
                                        <p style={{ position: 'absolute', fontWeight: 'bold', fontSize: '2em', margin: '1%' }}>{index + 1}</p>
                                        {isFinished ? 
                                            <img src={require(`../../Images/QuestImages/${newOrder[index+1].file}`)} draggable={false} style={{ width: '100%', height: '100%', objectFit: 'contain' }}></img>
                                            :
                                            ''
                                        }
                                      </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='bank' onDragOver={handleDragOver} onDrop={handleOnDrop}>
                        <p style={{ margin: '1%', fontWeight: 'bold', height: '30px' }}>Image Bank</p>
                        {!isFinished ? Object.keys(newOrder).map((value, index) => {
                            return (
                                <div key={value + index} id={value} className='item' draggable onDragStart={(e) => handleOnDrag(e, value)}>
                                    <img src={require(`../../Images/QuestImages/${newOrder[value].file}`)} style={{ width: '80%', height: '80%', objectFit: 'contain' }} />
                                    {/* <select id={value} style={{margin: 'auto', width: '25%', justifySelf: 'center', padding: '1%'}} onChange={(e) => {e.target.style.border = ''}}>
                                        {Object.keys(newOrder).map((value, index) => {
                                            return (
                                                <option>{index + 1}</option>
                                            )
                                        })}
                                    </select> */}
                                </div>
                            )
                        }): ''}
                    </div>
                </div>
                <div style={{ display: 'grid', width: '100%' }}>
                    {!isFinished && <button style={{ justifySelf: 'center', width: '25%' }} onClick={() => CheckAnswers()}>Check Answers</button>}
                </div>
            </div>
        </>
    )
}

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Matching);
export { connection as Matching };