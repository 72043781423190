//--------------------------------------------------
//Classroom Page for Teachers
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from "../../Actions";
import { DashboardNav } from "../GeneralComponents";
import { Row, Col } from 'react-bootstrap'

import placeholder from '../../Images/placeholder.jpg';

const ExhibitList = ({ }) => {
    document.title = 'Exhibits';
    const params = new URLSearchParams(new URL(window.location.href).search);
    const student_id = params.get("student");
    const token = localStorage.getItem("token");

    const [exhibits, setExhibits] = useState([]);
    const [openQuests, setOpenQuests] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [username, setUsername] = useState("");
    const [classroomName, setClassroomName] = useState("");
    const [classroomID, setClassroomID] = useState("");

    useEffect(() => {
        GetExhibits();
        userActions.GetStudentQuestData(student_id, token, (quest_data, exhibit_data, student_data, class_data) => {
            if (student_data) {
                setUsername(student_data.username);
            }
            if (class_data) {
                console.log("CLASS DATA: ", class_data);
                setClassroomName(class_data.name);
                setClassroomID(class_data.id);
            }
        });
    }, []);

    function GetExhibits() {
        userActions.GetStudentExhibits(student_id, 0, token, (data) => {
            setExhibits(data || []);
            setIsLoading(false);
        });
    }

    return (
        <Fragment>
            <div className='main-container page max-height-screen'>
                <head>
                    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
                </head>
                <DashboardNav />
                <div className={'studentView TravelogueView'}>
                    <Row className='headerRow'>
                        <Col sm={2} className='class-name'>
                            <a href={'/classroom/' + classroomID}>{classroomName}</a>
                        </Col>
                        <Col sm={8}>
                            <h1 className='Approval-Travelogue-Header'>Exhibits</h1>
                        </Col>
                        <Col sm={2} className='class-name'>
                            <a href={'/classroom/view/student?classroom=' + classroomID + '&student=' + student_id}><h3>{username}</h3></a>
                        </Col>
                    </Row>
                    <div className='studentContainer'>
                        {isLoading ? (
                            <div className='studentRow'>
                                <span className='bold font-lg grid-col-12'>Loading exhibits...</span>
                            </div>
                        ) : exhibits.length === 0 ? (
                            <div className='studentRow'>
                                <span className='bold font-lg grid-col-12'>This student has not yet completed any exhibits!</span>
                            </div>
                        ) : (
                            exhibits.map((exhibit) => (
                                <div className="Quest" key={exhibit.id}>
                                    <div className='dropdown' onClick={() => toggleQuest(exhibit.quest_number)}>
                                        <h1>Quest {exhibit.quest_number}</h1>
                                        <span className="material-symbols-outlined">
                                            {openQuests.includes(exhibit.quest_number) ? "keyboard_arrow_down" : "keyboard_arrow_right"}
                                        </span>
                                    </div>

                                    {openQuests.includes(exhibit.quest_number) && (
                                        <div className="phasesContainer">
                                            <ExhibitImage questKey={exhibit.quest_number} />
                                            <div className='Phase'><h3>What happened?</h3><p>{exhibit.what_val}</p></div>
                                            <div className='Phase'><h3>Who was involved?</h3><p>{exhibit.who_val}</p></div>
                                            <div className='Phase'><h3>Where did it happen?</h3><p>{exhibit.where_val}</p></div>
                                            <div className='Phase'><h3>When did it happen?</h3><p>{exhibit.when_val}</p></div>
                                            <div className='Phase'><h3>Why is this an important part of Idaho history?</h3><p>{exhibit.why_val}</p></div>
                                        </div>
                                    )}
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    )

    function ExhibitImage({ questKey }) {
        const [icon, setIcon] = useState(null);

        useEffect(() => {
            userActions.GetExhibitImage(student_id, questKey, (data) => {
                setIcon(data?.url || placeholder);
            });
        }, [student_id, questKey]);

        return (
            <div>
                <img width={400} height={"auto"} src={icon} alt="Exhibit" />
            </div>
        );
    }

    function toggleQuest(questKey) {
        setOpenQuests((prev) =>
            prev.includes(questKey)
                ? prev.filter((quest) => quest !== questKey)
                : [...prev, questKey]
        );
    }
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(ExhibitList);
export { connection as ExhibitList };