import { combineReducers } from 'redux';
import { session } from './session_reducer';
import { alert } from './alert_reducer';

//Combines all reducers for redux
const rootReducer = combineReducers({
    session,
    alert
});

export default rootReducer;