//--------------------------------------------------
//Classroom Page for Teachers
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

const ConfirmationPopup = ({ display, type, data, confirmation }) => {

    const [showBanner, setBanner] = useState('hidden')
    const [popupData, setPopupData] = useState({});
    const [classCode, setClassCode] = useState(0);

    useEffect(() => {
        setBanner(display);
        setPopupData(data);

    }, [display, type, data, confirmation])

    return (
        <Fragment>
            {type === 'removeStudent' ?
                <section className={`bannerBackground ${showBanner}`}>
                    <section className={`bannerPopup`}>
                        <div className='header'>Are you sure?</div>
                        <img src={popupData.icon}></img>
                        <div className='content'>You are removing {popupData.student}. <br></br> Removing a student will remove all of their progress and they will need to register again.</div>
                        <div className='buttons'>
                            <button className='red' onClick={() => confirmation(true)}>Remove</button>
                            <button className='purple' onClick={() => confirmation(false)}>Cancel</button>
                        </div>
                    </section>
                </section>
            : type === 'deleteClassroom' ?
                <section className={`bannerBackground ${showBanner}`}>
                    <section className={`bannerPopup`}>
                        <div className='header'>Are you sure?</div>
                        <img src={popupData.icon}></img>
                        <div className='content'>You are deleting classroom {popupData.class}. <br></br> Deleting a classroom will delete all student data from this class and they will need to register again.</div>
                        <div className='buttons'>
                            <button className='red' onClick={() => confirmation(true)}>Remove</button>
                            <button className='purple' onClick={() => confirmation(false)}>Cancel</button>
                        </div>
                    </section>
                </section>
            : type === 'deleteAccount' ?
            <section className={`bannerBackground ${showBanner}`}>
                <section className={`bannerPopup`}>
                    <div className='header'>Are you sure?</div>
                    <img src={popupData.icon}></img>
                    <div className='content'>You are deleting your account. <br></br> Deleting a teacher account will delete all classrooms and students inside.</div>
                    <div className='buttons'>
                        <button className='red' onClick={() => confirmation(true)}>Delete</button>
                        <button className='purple' onClick={() => confirmation(false)}>Cancel</button>
                    </div>
                </section>
            </section>
            : type === 'studentTeacher' ?
            <section className={`bannerBackground ${showBanner}`}>
                <section className={`bannerPopup`}>
                    <div className='header'>Create Teacher Student Account</div>
                    <div className='confirmInputRow'>
                        <label>Classroom Code</label>
                        <input type='number' value={classCode} onChange={(e) => setClassCode(e.target.value)}></input>
                    </div>
                    <div className='content'>Please enter a classroom code to join.</div>
                    <div className='buttons'>
                        <button className='purple' onClick={() => confirmation(true, classCode)}>Create</button>
                        <button className='red' onClick={() => confirmation(false)}>Cancel</button>
                    </div>
                </section>
            </section>
            :
                <></>
            }
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(ConfirmationPopup);
export { connection as ConfirmationPopup };