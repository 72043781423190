//--------------------------------------------------
// Classroom Page for Teachers - Displays students in class
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from "../../Actions";
import { FaArrowsRotate } from "react-icons/fa6";
import { DashboardNav, ConfirmationPopup } from "../GeneralComponents";

import placeholder from '../../Images/placeholder.jpg'

const Classroom = ({ }) => {
    const token = localStorage.getItem('token');
    const id = window.location.href.split('/')[window.location.href.split('/').length - 1];

    const [studentToRemove, setStudentToRemove] = useState('');
    const [showBanner, setBanner] = useState('hidden');
    const [studentRemoveName, setStudentRemoveName] = useState('');
    const [updateChild, setUpdateChild] = useState(Date.now());

    const [classContent, setClassContent] = useState({ classroom: {}, students: [] });
    const [icons, setIcons] = useState({});
    const [currentIcon, setCurrentIcon] = useState(null);

    useEffect(() =>
        userActions.GetClassroomStudents(id, token, (data) => {
            if (data) {
                setClassContent(data);
                document.title = `Classroom - ${data.classroom.name}`;
            }
    }), []);

    useEffect(() => {
        if (classContent?.students?.length > 0) {
            classContent.students.forEach((student) => {
                userActions.GetIcon(student.id, (data) => {
                    setIcons((prevIcons) => ({ ...prevIcons, [student.id]: data.url }));
                });
            });
        }
    }, [classContent]);

    const TryRemoveStudent = (studentID, name) => {
        setStudentToRemove(studentID);
        setStudentRemoveName(name);
        setBanner('');
    }

    const ConfirmRemoveStudent = (confirmation) => {
        if (confirmation) {
            userActions.RemoveStudent(studentToRemove, id, token, () => {
                setUpdateChild(Date.now())
            });
        }
        setBanner('hidden');
        setStudentToRemove(null);
        setStudentRemoveName('');
    }
    
    const RefreshClassroomCode = (element) => {
        userActions.RefreshClassroomCode(id, token, (data) => {
            element.innerHTML = data;
        })
    }
    
    return (
        <Fragment>
            <div className='main-container page max-height-screen'>
                <DashboardNav />
                <div className={'studentView'}>
                <div className='grid-col-2 headerRow'>
                    <h1 className='sectionHeader'>{classContent.classroom?.name}: Student List <span className='text-black'>({classContent.students?.length})</span></h1>
                    <h1 className='sectionHeader'><span>Code:</span> <span className='text-black'>{classContent.classroom?.code}</span><FaArrowsRotate className={'refresh-arrows'} onClick={(e) => RefreshClassroomCode(e.target.parentElement.children[1])} /></h1>
                </div>
                <div className='studentContainer'>
                    {classContent?.students?.length > 0 ? classContent.students?.map(index => {
                        return (
                            <div className='studentRow'>
                                <div className='imageContainer'><img src={icons[index.id] || placeholder}></img> </div>
                                <div className='studentSection'>
                                    <span className='grid-col-2 smaller-student-text'>Username: </span><span className='bold font-lg'>{index.username}</span>
                                    <br/>
                                    <p id='student-id' className='smaller-student-text'>Student ID: {index.student_id}</p>
                                </div>
                                <div className='links'>
                                    <div className='bold font-lg linkElement'>
                                        <a className='classlist-link' href={'/classroom/view/verify?classroom=' + id + '&student=' + index.id}>Approve</a>
                                    </div>
                                    <div className='bold font-lg linkElement'>
                                        <a className='classlist-link' href={'/classroom/view/student?classroom=' + id + '&student=' + index.id}>View Progress</a>
                                    </div>
                                    <div className='bold font-lg linkElement'>
                                        <a className='classlist-link text-red' onClick={() => {TryRemoveStudent(index.id, index.username); setCurrentIcon(icons[index.id]);}}>Remove</a>
                                    </div>
                                </div>
                            </div>
                        )
                    }) :
                        <div className='studentRow'>
                            <span className='bold font-lg grid-col-12'>Invite Students by sharing your class code!</span>
                        </div>
                    }
                </div>
            </div>
                <ConfirmationPopup display={showBanner} type={"removeStudent"} data={{"student": studentRemoveName, "icon": currentIcon}} confirmation={ConfirmRemoveStudent} />
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Classroom);
export { connection as Classroom };