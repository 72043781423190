//--------------------------------------------------
//Quest Sources
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TTSButton } from '../GeneralComponents';

import { FaArrowLeft, FaArrowRight, FaCamera } from "react-icons/fa";

const Sources = ({ sources, togglePhaseButton, type, category, currentPhasePos, message, title, AddSnapshotSource, AddTeachMoreSource }) => {
    const [content, setContent] = useState(null);
    const [currentImagePos, setCurrentImagePos] = useState(0);
    const [currentImage, setCurrentImage] = useState(null);
    const [currentExtraImages, setCurrentExtraImages] = useState([]);
    const [currentText, setCurrentText] = useState('');
    const [currentImageCitation, setCurrentImageCitation] = useState("");
    const [currentCitation, setCurrentCitation] = useState('Image Citation');
    const [currentTitle, setCurrentTitle] = useState('Source Title');
    const [currentAltText, setCurrentAltText] = useState('');
    const [currentType, setCurrentType] = useState('Text');

    const [disableRightArrow, setDisableRightArrow] = useState('');
    const [disableLeftArrow, setDisableLeftArrow] = useState('arrows-grey');

    const [adaptation, setAdaptation] = useState('');
    const [adaptationCitation, setAdaptationCitation] = useState('');
    const [toggle, setToggle] = useState(false);

    const [isSnapshot, setIsSnapshot] = useState('hidden');
    const [isTeachMore, setIsTeachMore] = useState('hidden');
    const [snapshotSource, setSnapshotSource] = useState('');

    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        setIsTeachMore('hidden')
        setIsSnapshot('hidden');
        setContent(sources);
        setToggle(false);
        setCurrentImage(null);
        setCurrentImagePos(0)
        resetArrows();
        if (!sources || sources?.length < 1) {
            setCurrentCitation('');
            togglePhaseButton(true);
            setDisableLeftArrow('arrows-grey');
            setDisableRightArrow('arrows-grey');
        }
        else {
            setIsPlaying(false);
            setCurrentTitle(sources[0].title);
            setCurrentType(sources[0].type);
            const temp = ['Text', 'Video', 'ImageWithText']
            if (temp.includes(sources[0].type)) {
                setCurrentImage(sources[0].file);
                if (sources[0].type === 'ImageWithText') {
                    setCurrentImage(require(`../../Images/QuestImages/${sources[0].file}`));
                    setCurrentText(sources[0].text);
                    setCurrentImageCitation(sources[0].imageCitation ? sources[0].imageCitation : "");
                    setCurrentExtraImages(sources[0].extraImages ? sources[0].extraImages : []);
                    setCurrentAltText(sources[0].altText);
                }
                if (sources[0].type === 'Video') {
                    setCurrentAltText(sources[0].altText);
                }
            }
            else {
                setCurrentImage(require(`../../Images/QuestImages/${sources[0].file}`));
                setCurrentExtraImages(sources[0].extraImages ? sources[0].extraImages : []);
                setCurrentImageCitation(sources[0].imageCitation ? sources[0].imageCitation : "");
                setCurrentAltText(sources[0].altText);
            }
            setCurrentCitation(sources[0].citation);
            setCurrentImagePos(0);
        }
        if (sources?.length < 2) {
            setAdaptation('');
            setAdaptationCitation('');
            togglePhaseButton(true);
            setDisableLeftArrow('arrows-grey');
            setDisableRightArrow('arrows-grey');
        } else if(sources?.length < 3 && sources[1]?.type == 'Adaptation'){ //if there is only one source, but it has an adaptation, disable the arrows to the next source
            togglePhaseButton(true);
            setDisableLeftArrow('arrows-grey');
            setDisableRightArrow('arrows-grey');
        }else if (sources) {
            setAdaptation(sources[1]?.type == 'Adaptation' ? sources[1]?.text : '');
            setAdaptationCitation(sources[1]?.type == 'Adaptation' ? sources[1]?.citation : '');
        }

        SetSnapshotVisibility(category)
    }, [sources]);

    const moveImage = (direction) => {
        if (direction > 0 && disableRightArrow == 'arrows-grey') {
            return;
        }
        if (direction < 0 && disableLeftArrow == 'arrows-grey') {
            return;
        }

        setAdaptation('');
        setAdaptationCitation('');
        if (content.length <= currentImagePos + direction) {
            return;
        }
        else if (currentImagePos + direction < 0) {
            return;
        }
        setDisableLeftArrow(currentImagePos + direction <= 0 ? 'arrows-grey' : '');
        if (content.length <= currentImagePos + direction + 1 || (content[content.length - 1].type == 'Adaptation' && currentImagePos + direction == content.length - 2)) {
            setDisableRightArrow('arrows-grey')
        }
        else {
            setDisableRightArrow('')
        }
        setCurrentType(content[currentImagePos + direction].type);

        if (content[currentImagePos + direction].type == 'Adaptation') {
            moveImage(direction * 2);
            return;
        }

        const temp = ['Text', 'Video', 'ImageWithText']
        if (temp.includes(content[currentImagePos + direction].type)) {
            setCurrentImage(content[currentImagePos + direction].file);
            if (sources[currentImagePos + direction].type === 'ImageWithText') {
                setCurrentText(sources[currentImagePos + direction].text);
                setCurrentImageCitation(sources[currentImagePos + direction].imageCitation ? sources[currentImagePos + direction].imageCitation : "");
                setCurrentImage(require(`../../Images/QuestImages/${formateFileName(content[currentImagePos + direction].file)}`));
                setCurrentExtraImages(sources[currentImagePos + direction].extraImages ? sources[currentImagePos + direction].extraImages : []);
                setCurrentAltText(sources[currentImagePos + direction].altText);
            }
            if (content[currentImagePos + (direction + 1)] && content[currentImagePos + (direction + 1)].type == 'Adaptation') {
                setAdaptation(content[currentImagePos + (direction + 1)].text)
                setAdaptationCitation(content[currentImagePos + (direction + 1)].citation);
                //Spawn button to toggle
            }
            if (sources[currentImagePos + direction].type === 'Video') {
                setCurrentAltText(sources[currentImagePos + direction].altText);
            }
        }
        else {
            setCurrentImage(require(`../../Images/QuestImages/${formateFileName(content[currentImagePos + direction].file)}`));
            setCurrentImageCitation(sources[currentImagePos + direction].imageCitation ? sources[currentImagePos + direction].imageCitation : "");
            setCurrentExtraImages(sources[currentImagePos + direction].extraImages ? sources[currentImagePos + direction].extraImages : []);
            setCurrentAltText(sources[currentImagePos + direction].altText);
        }

        setCurrentTitle(content[currentImagePos + direction].title);
        setCurrentCitation(content[currentImagePos + direction].citation)
        setCurrentImagePos(currentImagePos + direction);
        if (content.length <= currentImagePos + direction + 1) {
            togglePhaseButton(true);
        }
    }

    const resetArrows = () => {
        setDisableLeftArrow('arrows-grey');
        setDisableRightArrow('');
    }

    const formateFileName = (file) => {
        let temp = file.replace('#', '%23');
        return temp;
    }

    const SetSnapshotVisibility = (category) => {
        switch (category) {
            case 'snapshot':
                setIsSnapshot('')
                break;
            case 'teach_me_more':
                setIsTeachMore('')
                break;
            default:
                break;
        }
    }

    const SnapSource = (target) => {

        if (category !== 'snapshot') {
            setIsSnapshot('hidden');
            return;
        }

        if (snapshotSource) {
            setDisableLeftArrow('');
            setDisableRightArrow('');
            setSnapshotSource('');
        }
        else {
            setDisableLeftArrow('arrows-grey');
            setDisableRightArrow('arrows-grey');
            setSnapshotSource(currentImage);
            AddSnapshotSource(currentImage, currentImagePos);
        }

    }

    const AddTeachMore = (target) => {

        if (category !== 'teach_me_more') {
            setIsTeachMore('hidden');
            return;
        }

        AddTeachMoreSource(currentImage);

    }

    return (
        <Fragment>
            <TTSButton isActive={message != "" ? true : false} utterance={title + " ... " + message} isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
            <div className='quest-source-container' id='sources'>
                <h2 className='grid-col-12 quest-description'><strong>{title}</strong><br/>{message}</h2>
                <section className='grid-col-12 panel-shadow white-source-container'>
                    <FaArrowLeft className={`side-arrows ${disableLeftArrow}`} onClick={() => {moveImage(-1); setToggle(false); setIsPlaying(false);}} />
                    <TTSButton 
                        isActive={true} 
                        utterance={
                            currentType === "ImageWithText"
                            ? (toggle ? `${currentTitle} ... ${currentAltText} ... ${currentImageCitation} ... ${adaptation}` : `${currentTitle} ... ${currentAltText} ... ${currentImageCitation} ... ${currentText}`)
                                : currentType === "Text"
                                    ? (toggle ? `${currentTitle} ... ${adaptation}` : `${currentTitle} ... ${currentImage}`)
                                    : `${currentTitle} ... ${currentAltText} ... ${currentImageCitation}`
                        } 
                        isPlaying={isPlaying} 
                        setIsPlaying={setIsPlaying} 
                    />
                    <div id='source-citation-box' style={isSnapshot == '' || isTeachMore == '' ? {marginBottom: '8%'} : {marginBottom: ''}}>
                        <h2 className='source-title'>{currentTitle}</h2>
                        <div className='stat inline-flex' style={{ display: adaptation != '' ? 'flex' : 'none', justifySelf: 'end', padding: '1%' }}>
                            <label className="switch">
                                <input type="checkbox" onChange={(e) => setToggle(e.target.checked)} checked={toggle ? true : false}></input>
                                <span className="slider round"></span>
                            </label>
                            <span>Adapted Version</span>
                        </div>
                        {currentType == 'Text' ? 
                            currentPhasePos == 0 ? 
                                <p className='source-text'>{toggle ? adaptation : currentImage}</p> 
                                : <p className='source-text'><span style={{ fontSize: '2em', fontWeight: 'bold' }}>Source:</span><br></br>{toggle ? adaptation : currentImage}</p> 
                            : currentType == 'Video' ? 
                                <iframe src={currentImage} style={{ height: '100%', width: '100%' }} controls allow={'autoplay'} allowFullScreen></iframe> 
                            : currentType == 'ImageWithText' ?
                                <div className='source-image'>
                                    <p className='source-header'>Source:</p>
                                    <div className='source-image-container'>
                                        <div className='source-image-block'>
                                            <img src={currentImage}></img>
                                            <p>{currentImageCitation}</p>
                                        </div>
                                        {currentExtraImages.length > 0 &&
                                        currentExtraImages.map((img, index) => (
                                            <div className='source-image-block' key={index}>
                                                <img src={require(`../../Images/QuestImages/${img.image}`)}></img><br/>
                                                <p>{img.imageCitation}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <p className='source-text'>{toggle ? adaptation : currentText}</p> 
                                </div>
                            :
                            <div className='source-image'>
                                <div className='source-image-container'>
                                    <div className='source-image-block'>
                                        <img src={currentImage}></img>
                                    </div>
                                    <p>{currentImageCitation}</p>
                                        {currentExtraImages.length > 0 &&
                                        currentExtraImages.map((img, index) => (
                                            <div className='source-image-block' key={index}>
                                                <img src={require(`../../Images/QuestImages/${img.image}`)}></img>
                                                <p>{img.imageCitation}</p>
                                            </div>
                                        ))}
                                </div>
                            </div>}
                            
                        {currentPhasePos == 0 ? 
                            <></>
                            : <p className='source-citation'><span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Citation:</span><br></br> {toggle ? adaptationCitation : currentCitation}</p>
                        }
                    </div>

                    <FaArrowRight className={`side-arrows ${disableRightArrow}`} onClick={() => {moveImage(1); setToggle(false); setIsPlaying(false);}} />
                    <div className={`snapshot-container ${isSnapshot}`} >
                        <p>Take Snapshot</p>
                        <FaCamera style={snapshotSource ? { color: 'var(--purple)' } : { color: '2e2e2e' }} size={'4vi'} onClick={(e) => SnapSource(e.target)} />
                    </div>
                    <div className={`snapshot-container ${isTeachMore}`}>
                        <p>Add Source</p>
                        <FaCamera style={{ color: '2e2e2e' }} size={'4vi'} onClick={(e) => AddTeachMore(e.target)} />
                    </div>
                </section>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Sources);
export { connection as Sources };