import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { AiFillSound } from "react-icons/ai";
import { FaPlay, FaPause, FaStop } from "react-icons/fa";

const TTSButton = ({ isActive, utterance, isPlaying, setIsPlaying }) => {
    const [TTSEnabled, setTTSEnabled] = useState(false);
    const [speechInstance, setSpeechInstance] = useState(null);

    useEffect(() => {
        if (!isPlaying) {
            speechSynthesis.cancel();
            setTTSEnabled(false);
        }
    }, [isPlaying]); // Stop speech when isPlaying becomes false

    return (
        <Fragment>
            {!TTSEnabled ? (
                <button
                    className='tts-button'
                    onClick={() => {
                        const speechUtterance = new SpeechSynthesisUtterance(utterance);
                        
                        // Handle speech end to reset the button state
                        speechUtterance.onend = () => {
                            setTTSEnabled(false);
                            setIsPlaying(false);
                        };

                        speechSynthesis.speak(speechUtterance);
                        setSpeechInstance(speechUtterance);
                        setTTSEnabled(true);
                        setIsPlaying(true);
                    }}
                    disabled={!isActive}
                >
                    <AiFillSound color={"#8d5847"} size={"25px"} />
                </button>
            ) : (
                <div className='tts-button tts-pause'>
                    <button
                        className='tts-button'
                        onClick={() => {
                            if (isPlaying) {
                                speechSynthesis.pause();
                                setIsPlaying(false);
                            } else {
                                speechSynthesis.resume();
                                setIsPlaying(true);
                            }
                        }}
                    >
                        {isPlaying ? <FaPause /> : <FaPlay />}
                    </button>
                    <button
                        className='tts-button'
                        onClick={() => {
                            speechSynthesis.cancel();
                            setTTSEnabled(false);
                            setIsPlaying(false);
                        }}
                    >
                        <FaStop color='#8d5847' size={"20px"} />
                    </button>
                </div>
            )}
        </Fragment>
    );
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {};

const connection = connect(mapState, actionCreators)(TTSButton);
export { connection as TTSButton };
