import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from "../../Actions";
import { DashboardNav } from "../GeneralComponents";
import { Row, Col } from 'react-bootstrap'

const TravelogueList = ({ }) => {
    document.title = 'Travelogue';
    const params = new URLSearchParams(new URL(window.location.href).search);
    const classroom_id = params.get("classroom");
    const student_id = params.get("student");
    const token = localStorage.getItem("token");

    const [travelogue, setTravelogue] = useState([]);
    const [username, setUsername] = useState("");
    const [classroomName, setClassroomName] = useState("");
    const [openQuests, setOpenQuests] = useState([]);
    const [openPhases, setOpenPhases] = useState({});

    useEffect(() => {
        GetTravelogue();
        userActions.GetStudentQuestData(student_id, token, (quest_data, exhibit_data, student_data, class_data) => {
            if (student_data) {
                setUsername(student_data.username);
            }
            if (class_data) {
                setClassroomName(class_data.name);
            }
        });
    },
        []);

    function GetTravelogue() {
        userActions.GetStudentTravelogue(student_id, 0, token, (data) => {
            if (data.length > 0) {
                setUsername(data[0].username);
                const groupedByQuest = data.reduce((acc, log) => {
                    const { quest_number } = log;
                    if (!acc[quest_number]) {
                        acc[quest_number] = [];
                    }
                    acc[quest_number].push(log);
                    return acc;
                }, {});
                setTravelogue(groupedByQuest);
            }
        })
    }

    return (
        <Fragment>
            <div className='main-container page max-height-screen'>
                <head>
                    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
                    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
                </head>
                <DashboardNav />
                <div className={'studentView TravelogueView'}>
                    <Row className='headerRow'>
                        <Col sm={2} className='class-name'>
                            <a href={'/classroom/' + classroom_id}>{classroomName}</a>
                        </Col>
                        <Col sm={8}>
                            <h1 className='Approval-Travelogue-Header'>Travelogue</h1>
                        </Col>
                        <Col sm={2} className='class-name'>
                            <a href={'/classroom/view/student?classroom=' + classroom_id + '&student=' + student_id}><h3>{username}</h3></a>
                        </Col>
                    </Row>

                    <div className='studentContainer' >
                        {travelogue.length == 0 ?
                            <div className='studentRow'>
                                <span className='bold font-lg grid-col-12'>This student has not yet completed any phases!</span>
                            </div> :
                            Object.keys(travelogue).map((questKey) => (
                                <div key={questKey} className="Quest">
                                    <div className='dropdown' onClick={() => toggleQuest(questKey)}>
                                        <h1>Quest {questKey}</h1>
                                        {openQuests.includes(questKey) ? <span class="material-symbols-outlined">keyboard_arrow_down</span> : <span class="material-symbols-outlined">keyboard_arrow_right</span>}
                                    </div>

                                    {openQuests.includes(questKey) && (
                                        <div className="phasesContainer">
                                            {QuestDetails(travelogue[questKey], questKey)}
                                        </div>
                                    )}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )

    function QuestDetails(answers, questKey) {
        const groupedByPhase = answers.reduce((acc, log) => {
            const { phase_number } = log;
            if (!acc[phase_number]) {
                acc[phase_number] = [];
            }
            acc[phase_number].push(log);
            return acc;
        }, {});
        return (
            <div className='Phase'>
                {Object.keys(groupedByPhase).map((phaseNumber) => {
                    // Check if the phase is open for the given questKey and phaseNumber
                    const isPhaseOpen = openPhases[questKey]?.includes(phaseNumber) || false;

                    return (
                        <div key={phaseNumber}>
                            <div className='dropdown'>
                                <h2 onClick={() => togglePhase(questKey, phaseNumber)}>
                                    Phase {parseInt(phaseNumber) + 1}
                                </h2>
                                {isPhaseOpen ? (
                                    <span className="material-symbols-outlined">keyboard_arrow_down</span>
                                ) : (
                                    <span className="material-symbols-outlined">keyboard_arrow_right</span>
                                )}
                            </div>

                            {isPhaseOpen && (
                                <div className='phaseContent'>
                                    {groupedByPhase[phaseNumber].map((log) => {
                                        return (
                                            <div key={log.id}>
                                                <h3>{log.answer_type}: </h3>
                                                {log.answer !== "" ? (
                                                    log.answer_type === "Source" ?
                                                        log.answer.startsWith("/static/media") ?
                                                            <img
                                                                style={{ width: '25%' }}
                                                                src={log.answer}
                                                                alt="Source Image"
                                                            />
                                                            :
                                                            log.answer.startsWith("http") ?
                                                                <iframe
                                                                    src={log.answer}
                                                                    title="Source Iframe"
                                                                    allowFullScreen
                                                                    style={{ width: "100%", height: "500px" }}
                                                                ></iframe> :
                                                                <p>{log.answer}</p>
                                                        :
                                                        <p>{log.answer}</p>
                                                ) : (
                                                    <p>Incomplete</p>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }

    // Function to toggle quest dropdown
    function toggleQuest(questKey) {
        setOpenQuests((prev) =>
            prev.includes(questKey)
                ? prev.filter((quest) => quest !== questKey)
                : [...prev, questKey]);
    };

    // Function to toggle phase dropdown per quest
    function togglePhase(questKey, phaseKey) {
        setOpenPhases((prev) => {
            const updatedPhases = { ...prev };

            if (!updatedPhases[questKey]) {
                updatedPhases[questKey] = [];
            }

            if (updatedPhases[questKey].includes(phaseKey)) {
                updatedPhases[questKey] = updatedPhases[questKey].filter((phase) => phase !== phaseKey);
            } else {
                updatedPhases[questKey] = [...updatedPhases[questKey], phaseKey];
            }

            return updatedPhases;
        });
    };
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(TravelogueList);
export { connection as TravelogueList };