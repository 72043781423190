//--------------------------------------------------
//Classroom Page for Teachers
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';
import { Col, Row, Container } from 'react-bootstrap';
import placeholder from '../../Images/placeholder.jpg'

const ClassCreationForm = ({ display, confirmation, editConfirmation, id, name, grade, icon, edit }) => {

    const [showBanner, setBanner] = useState('hidden');
    const [class_id, setClassID] = useState(id);
    const [className, setClassName] = useState(`${name}`);
    const [classGrade, setClassGrade] = useState(grade);
    const [classIcon, setClassIcon] = useState(icon);
    const [isEdit, setIsEdit] = useState(edit);
    const [onChange, setOnChange] = useState(false);

    useEffect(() => {
        setBanner(display);
        setClassName(`${name}`);
        setClassGrade(grade);
        setClassIcon(icon);
        setIsEdit(edit);

    }, [display, confirmation, name, grade, edit])

    const onIconUpload = (icon, convert) => {
        setClassIcon(icon);
    }

    return (
        <Fragment>
            <section className={`bannerBackground ${showBanner}`}>
                <section className={`createClass`}>
                    {isEdit ? <h1>Edit Class</h1>
                    :<h1>Create a Class</h1>}
                    <div className='inputRow'>
                        <label>Classroom Name</label>
                        <input type='text' value={className} onChange={(e) => setClassName(e.target.value)} placeholder={className}></input>
                    </div>
                    <div className="inputGrid">
                        <div className='inputRow inputSmall'>
                            <label>Classroom Grade</label>
                            <select value={classGrade} onChange={((e) => setClassGrade(e.target.value))}>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                            {/*<input type='text' value={classGrade} onChange={(e) => setClassGrade(e.target.value)} placeholder='Class Grade Here'></input> */}
                        </div>
                        {isEdit ? 
                        <Container className='inputRow inputSmall'>
                            <Row>
                                <Col xs={4}>
                                    <img className='classImgEdit' src={onChange && classIcon instanceof Blob ? URL.createObjectURL(classIcon) : classIcon ? classIcon : placeholder}></img>
                                </Col>
                                <Col xs={8}>
                                    <label>Current Image</label>
                                    <br/>
                                    <input className="classFileUpload" type='file' onChange={(e) => {onIconUpload(e.target.files[0]); setOnChange(true);}}/>
                                </Col>
                            </Row>
                        </Container>
                        :
                        <div className='inputRow inputSmall'>
                            <label>Classroom Icon</label>
                            <div className="inputGrid">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 15 25" fill="none">
                                    <path d="M16 6.5V17C16 19.21 14.21 21 12 21C9.79 21 8 19.21 8 17V6.5C8 5.12 9.12 4 10.5 4C11.88 4 13 5.12 13 6.5V16C13 16.55 12.55 17 12 17C11.45 17 11 16.55 11 16V6.5H9.5V16C9.5 17.38 10.62 18.5 12 18.5C13.38 18.5 14.5 17.38 14.5 16V6.5C14.5 4.29 12.71 2.5 10.5 2.5C8.29 2.5 6.5 4.29 6.5 6.5V17C6.5 20.04 8.96 22.5 12 22.5C15.04 22.5 17.5 20.04 17.5 17V6.5H16Z" fill="#49454F"/>
                                </svg>
                                <input className="classFileUpload" type='file' onChange={(e) => onIconUpload(e.target.files[0])}/>
                            </div>
                        </div>}

                    </div>

                    <div className='buttons'>
                        {isEdit ? <button className='classEditBtn' onClick={() => {classIcon === null ? window.confirm('Please select a classroom icon.') : editConfirmation(true, class_id, className, classGrade, classIcon, onChange)}}>Edit</button>
                        :         <button className='purple' onClick={() => {classIcon === null ? window.confirm('Please select a classroom icon.') : confirmation(true, className, classGrade, classIcon); setOnChange(false);}}>Create</button>}
                        <button className='red' onClick={() => {confirmation(false); setOnChange(false);}}>Cancel</button>
                    </div>
                </section>
            </section>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(ClassCreationForm);
export { connection as ClassCreationForm };