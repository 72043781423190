//--------------------------------------------------
//Rendered Component when opening website for the first time
//--------------------------------------------------

import React, { Fragment, useCallback, useEffect } from "react";
import { connect } from 'react-redux';
import { Unity as UnityComponent, useUnityContext } from "react-unity-webgl";
import { useNavigate } from "react-router-dom";

const Unity = ({ }) => {
  const navigate = useNavigate();

  const { unityProvider, addEventListener, removeEventListener } = useUnityContext({
    loaderUrl: "/build/Build/build.loader.js",
    dataUrl: "/build/Build/build.data",
    frameworkUrl: "/build/Build/build.framework.js",
    codeUrl: "/build/Build/build.wasm",
  });
    
const handleButtonClick = useCallback(() => {
  console.log("got event");
  navigate("/");
}, []);

useEffect(() => {
  addEventListener("ClickedStar", handleButtonClick);
  return () => {
    removeEventListener("ClickedStar", handleButtonClick);
  };
}, [addEventListener, removeEventListener, handleButtonClick]);

  return (
    <Fragment>
      <UnityComponent unityProvider={unityProvider} style={{width:"80%"}} />
    </Fragment>
  )
};

function mapState(state) {
  const { session } = state;
  return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Unity);
export { connection as Unity };